import React from 'react'
import { Link, graphql, PageProps } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import DefaultSiteLayout from './default-site-layout'
import { MDXProvider } from '@mdx-js/react'
import NotakaosImg from '../images/twitter_icon_notakaos_800x800.png'
import { GlobalFooter } from '../components/global-footer'
import { FullWidthEnabledContext } from '../providers/FullWidthEnabledProvider'
import { Switch } from '@headlessui/react'
import { DefaultHelmet } from '../components/default-helmet'

type DefaultBlogTemplateDataType = {
  site: {
    siteMetadata: {
      siteName: string
      siteUrl: string
      siteDescription: string
    }
  }
  mdx: {
    id: string
    body: string
    fields: {
      slug?: string
    }
    frontmatter: {
      title: string
      date?: string
      draft?: boolean
      lang?: string
      description?: string
    }
  }
}
type DefaultBlogTemplateContextType = { slug: string }
type DefaultBlogTemplateProps = PageProps<DefaultBlogTemplateDataType, DefaultBlogTemplateContextType>

export default function DefaultBlogTemplate({ data }: DefaultBlogTemplateProps) {
  const { body: mdxBody, frontmatter } = data.mdx
  const { siteName, siteDescription, siteUrl } = data.site.siteMetadata
  const { title: blogTitle, date, lang, description } = frontmatter

  const mdxComponents = {
    // h1: (props: any) => <h1 className="px-3 mb-24 text-2xl sm:text-3xl sm:leading-snug" {...props} />,
    h1: (props: any) => (
      <div className="pb-10 mb-12 pt-28">
        <h1 className="px-4 pb-2 text-3xl leading-snug sm:text-4xl sm:leading-normal" {...props} />
        <div className="px-4 mb-24 text-sm text-gray-400">{date}</div>
      </div>
    ),
    h2: (props: any) => <h2 className="px-4 py-2 mt-0 text-2xl" {...props} />,
    h3: (props: any) => <h3 className="px-4 py-2 text-xl border-gray-500" {...props} />,
    h4: (props: any) => <h4 className="px-4 py-2 border-gray-500" {...props} />,
    h5: (props: any) => <h5 className="px-4 py-2 border-gray-500" {...props} />,
    h6: (props: any) => <h6 className="px-4 py-2 border-gray-500" {...props} />,
    p: (props: any) => <p className="px-4" {...props} />,
  }

  return (
    <FullWidthEnabledContext.Consumer>
      {({ fullWidthEnabled, setFullWidthEnabled }) => (
        <MDXProvider components={mdxComponents}>
          <DefaultSiteLayout>
            <DefaultHelmet
              siteName={siteName}
              siteDescription={siteDescription}
              siteUrl={siteUrl}
              title={blogTitle}
              ogType="article"
              lang={lang}
              description={description}
            />
            <header
              className={`
                sticky top-0 z-50 flex items-center justify-between px-4 py-3 bg-gray-200 dark:bg-gray-600 bg-opacity-50 dark:bg-opacity-50
                backdrop-filter backdrop-grayscale backdrop-blur-[2px] backdrop-contrast-100
            `}
            >
              <Link to={'/'} className="flex items-center">
                <img src={NotakaosImg} width="32" height="32" className="rounded-full" />
                <span className="pl-2">TOP</span>
              </Link>
              <div className="invisible sm:visible">
                <Switch.Group>
                  <div className="flex items-center justify-center">
                    <Switch.Label className="mr-2 text-sm text-gray-400" passive>
                      Full width
                    </Switch.Label>
                    <Switch
                      checked={fullWidthEnabled}
                      onChange={setFullWidthEnabled}
                      className={`${fullWidthEnabled ? 'bg-blue-300 dark:bg-blue-800' : 'bg-gray-200 dark:bg-gray-600'} bg-green
                        relative inline-flex flex-shrink-0 h-[22px] w-[46px] border-2 border-transparent rounded-full
                        cursor-pointer transition-colors ease-in-out duration-100 focus:outline-none focus-visible:ring-2
                        focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span className="sr-only">Toggle full width setting</span>
                      <span
                        aria-hidden="true"
                        className={`${fullWidthEnabled ? 'translate-x-6' : 'translate-x-0'}
                            pointer-events-none inline-block h-[18px] w-[18px] rounded-full bg-white shadow-lg transform
                            ring-0 transition ease-in-out duration-100`}
                      />
                    </Switch>
                  </div>
                </Switch.Group>
              </div>
            </header>

            <div className={`DefaultMdxTemplate ${fullWidthEnabled ? 'mx-0' : 'flex flex-col items-center'}`}>
              <article className={`prose max-w-none ${fullWidthEnabled ? 'w-screen' : 'max-w-3xl w-full'}`}>
                <MDXRenderer>{mdxBody}</MDXRenderer>
              </article>

              <GlobalFooter siteName={siteName} linkToTop={true} />
            </div>
          </DefaultSiteLayout>
        </MDXProvider>
      )}
    </FullWidthEnabledContext.Consumer>
  )
}

// NOTE: pageQuery - export defaultされたコンポーネント(BlogPageTemplate)に渡されるクエリ、ファイルごとに1つのページクエリしか持てない
export const pageQuery = graphql`
  query DefaultBlogTemplateQuery($slug: String!) {
    __typename
    site {
      siteMetadata {
        siteName
        siteUrl
        siteDescription
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        draft
        lang
        description
      }
    }
  }
`
