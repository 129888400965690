import React from 'react'
import { Helmet } from 'react-helmet'

type DefaultHelmetProps = {
  siteName: string
  siteDescription: string
  siteUrl: string
  ogType: string
  title?: string
  description?: string
  lang?: string
}

export function DefaultHelmet({ siteName, siteDescription, siteUrl, ogType, title, description, lang }: DefaultHelmetProps) {
  const pageTitle = title ? `${title} | ${siteName}` : siteName
  const htmlLang = lang || 'ja'
  const ogImage = `${siteUrl}/notakaos-com-ogp.png`
  const pageDescription = description || siteDescription

  return (
    <Helmet>
      <html lang={htmlLang} />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta property="og:type" content={ogType} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:title" content={pageTitle} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@notakaos" />
    </Helmet>
  )
}
