import React from 'react'
import { Link } from 'gatsby'

type GlobalFooterProps = {
  siteName: string
  linkToTop: boolean
}

export function GlobalFooter({ siteName, linkToTop }: GlobalFooterProps) {
  return (
    <footer className="pt-24 h-screen/2">
      <div className="flex justify-center">
        {linkToTop ? (
          <Link to={'/'} className="text-gray-500">
            {siteName}
          </Link>
        ) : (
          <></>
        )}
      </div>
    </footer>
  )
}
